import React, {useState} from 'react';
import '../../scss/singleSlide.scss';
import Vimeo from '@u-wave/react-vimeo';
import SlideApps from '../editorial/SlideApps';
import back from '../../assets/back.svg';
import { Link } from 'gatsby';
import {useSwipeable} from 'react-swipeable';
import { navigate } from "gatsby";

const SingleCarouselSlide = (props) => {

    const {info, storeID, slideType, links, noNavigate} = props;

    const [active, setActive] = useState(0);

    // const handlers = useSwipeable({ 
    //     onSwipedRight: () => navigate(`${links.prev}?storeID=${storeID}`),
    //     onSwipedLeft: () => navigate(`${links.next}?storeID=${storeID}`),
    // })

    return (
        <>
        {/* <div {...handlers} className="Carousel SingleCarouselSlide"> */}
        <div className="Carousel SingleCarouselSlide">
            <div className="img">
            {links?.prev && !noNavigate && (
                <button className='navBtn prevBtn'>
                    <Link to={`${links.prev}?storeID=${storeID}`}>
                        <img src={back}/>
                    </Link>
                </button>
            )}
            {links?.next && !noNavigate && (
                <button className='navBtn nextBtn'>
                    <Link to={`${links.next}?storeID=${storeID}`}>
                        <img src={back}/>
                    </Link>
                </button>
            )}
            {info?.mainCarousel.length > 0 && (
                <div className="main-carousel main-carousel-slngle">
                    {info?.mainCarousel.length>0 && info?.mainCarousel.map((item, i) => (
                    <div className={item?.image?.mediaItemUrl ? 'carousel-cell' : 'noImage carousel-cell'} key={i}>
                        {item?.image?.mediaItemUrl && (
                            !item?.vimeoId ? (
                                <img
                                    src={item?.image?.mediaItemUrl}
                                    key={i}
                                />
                            ) : (
                                <Vimeo 
                                    id={`slide-${i}`}
                                    className="vimeoPlayer" 
                                    video={Number(item.vimeoId)} 
                                    controls={false} 
                                    autoplay={false} 
                                    muted={true} 
                                    onReady={(v) => v.play}
                                />
                            )
                        )}
                        {slideType === 'appSlides' && (
                            <SlideApps
                                text={item?.text} 
                                qr={item?.qr} 
                                ico={item?.ico} 
                            />
                        )}
                    </div>
                    ))}
                </div>
            )}
            </div>
        </div>
    </>
    )
}

export default SingleCarouselSlide;

