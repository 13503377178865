import imgTV from '../../../src/images/data/SKYGO.png';
import ios from '../../../src/images/data/app/sky-go/skygo-ios.png';
import and from '../../../src/images/data/app/sky-go/skygo-android.png';
import ico from '../../../src/images/data/My_Sky_Sky_Go_logo_Retail_App_Assets_IT.png';

import logo from '../../../src/images-static/logo-colored.jpg';

const app = {
    data : {
        wp: {
            app: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                },
                links :
                    {
                    prev : '/apps/my-sky', 
                    next : '/apps/sky-news'
                },
                options : [
                    {
                        id: 'q-app',
                        ico : ico,
                        text : '<h1>Sky Go</h1><p>Stream and download great TV, films and live sport with the Sky Go app. You can stream your favourite channels, including free-to-air favourites like ITV and Channel 4, and depending on your Sky subscription, great dramas on Sky Atlantic and live sports on Sky Sports. So, whether your main TV is being used by the kids and you want to stream your favourite show at home or you want to download stuff whilst you’re out and about, Sky Go’s got you covered.</p>',
                        qr : [
                            {
                                img : ios,
                                code : 'ios',
                                link : '',
                                label : 'iOS'
                            },
                            {
                                img : and,
                                code : 'and',
                                link : '',
                                label : 'Android'
                            },
                            
                        ],
                        image: {
                            mediaItemUrl:imgTV
                        },
                    },
                ]
            }
        },

    }
}

export default app;